import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Improving The Readability Of Pytest Output (With Colour!)",
  "date": "2019-01-01T17:44:32.169Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/pytest-clarity-notes/",
  "category": "Software",
  "tags": ["testing", "python", "pytest", "projects"],
  "description": "Exploring how we can use a lesser known part of the Python standard library and the pytest plugin system to output colourful diffs similar to those seen in testing frameworks for languages such as Elixir and JavaScript."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://github.com/pytest-dev/pytest"
      }}>{`Pytest`}</a>{` is quickly becoming the de facto testing framework in the Python community, but I've always found the way it reports assertion errors to rather difficult to quickly parse in many circumstances. In this post, I'll explore how we can use a lesser known part of the Python standard library and the pytest plugin system to output colourful diffs similar to those seen in testing frameworks for languages such as Elixir and JavaScript. This `}<em parentName="p">{`isn't`}</em>{` a tutorial, but it should give you an overview of the following topics:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Getting started with making a pytest plugin`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How to use the Python standard library to calculate diffs between text`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A brief description of how to use partial application in Python`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Formatting terminal output with escape sequences (programming language agnostic)`}</p>
        <p parentName="li">{`Here's what a failing test looks like with `}<inlineCode parentName="p">{`pytest-clarity`}</inlineCode>{` installed:`}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/72bd3a3d617f2aebb970a259b9bd7545/0b533/pytest-clarity-unified.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.79754601226993%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAADJ0lEQVQ4y22UaU/kSAyG+bbcDX1BJ507lTqSStJJ+gIWmEEjZrSa//9znlUC04JdPlh2uVSPXpctH526ATM/YqMlTW7ojGZtFKVSpFLRaUVlNFJmNIXCNxKZJPwwEpumrFWGFSnXXkjPOjpfhpz10ETi5RWusswSxa00OLJgFkvmiWYcp3iVId6W3EYJKk5oM4GfZvSMczcY/AF47oVMshSn1HhNziiMcGtDtLUEm4JoY7nwQo5v/UHJ8bv18dlgb9ABeOIEzMMYqTLKQrFd5cyjmNIqnjaWn/c1ojZc+tGbmmXIxbs/COr9QeG75LEfk9oaWTUsdcEkSMmKmiyviIoV0yTjxOkVvtnZYMEn6NGf4NTxGQUJfl4Tle3gXVMS1x3TRDMXBkdZrnzBOMy4DgRXgeBiGX2CHv7wMoiYpAK561j9uEPsamZCknQrqu97sn2L3LU0rw/ULzva13vs85YbJTl1gkP5R2/qAiZJwkykJJsC/VDhWkX+uKL81rD9vaN4bhD7kvKl5UZnzJVgJgVXYXxQeACeuAE3QYQTxWit0UpxGwu2pWFfFzy3JV1VUFlDVxgu3fduL4JP5Q4lf/zDvjGONLjasjR2eDiNMpbK4qoCVxoWQjPyY04WHqcLj7P+3UfgcHACxkIx15ZxZrju47xkbiwzXQznqbbMjB3y41RxY2vcVcc4lf8vuU+MwhS/bIibNWm3I1h1LPISr66GfNJuEN2OtOsbkaPqGie3jCLxFTDkKhYsqxXy8ZG5KlgUFeJ+j1uVRJsNftPhlTXJ/g6nbDiZL/lr5nK6+KpkN+A6TgiagtXPHWFbIPY18mFF/bql+2ePfmqpfqyxL2vCzuJYzW2uuIq+6HJv/UXY5tiXFvvS4dd6GKHm15bN7z3675qwywmanGhtideWZWUYJ8nXfzhJJW5Vkz0+4FYV08xQvn5H3G8w3+4J2mZo2iwrmKlyGJvjW+8w1Gcft01vIy9CiIxdkeOECTLLeLIFaym50wqbSUqtKLRiEqXDdvrvcvgEvFgGLIMIHcdkcczUCymTmJ0UtFk6nPvxOj+sq88D/Qf4LyfJ9qLdxOIdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Colourful Pytest Output",
            "title": "Colourful Pytest Output",
            "src": "/static/72bd3a3d617f2aebb970a259b9bd7545/0b533/pytest-clarity-unified.png",
            "srcSet": ["/static/72bd3a3d617f2aebb970a259b9bd7545/222b7/pytest-clarity-unified.png 163w", "/static/72bd3a3d617f2aebb970a259b9bd7545/ff46a/pytest-clarity-unified.png 325w", "/static/72bd3a3d617f2aebb970a259b9bd7545/0b533/pytest-clarity-unified.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`From this output, it's easy to see `}<em parentName="p">{`at a glance`}</em>{` why your assertion has failed. Your eyes are
immediately drawn to the differences between the dictionaries, and the colours inform you
what would need to be added or removed from either side of the assertion in order for them to match. `}</p>
    <p>{`For comparison, I've attached the output for the same test using vanilla pytest below (with very verbose logging `}<inlineCode parentName="p">{`-vv`}</inlineCode>{` enabled).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d234200a32d468b6c78a223b70f22ff/8c557/without-clarity.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21472392638037%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABT0lEQVQoz5WSyVLDMBBEfQDKQDgAgYAdWxptTkKFsAaK4sCJ//+jpnpkKCAJy6E9tiW13izFYWPx1EXVVQyw4lSnrWDYisbGOtRGwL1HjUVl8n/GUSu6f9jmtaKsDaLzeJ5EXASPmxiwiEEPUDTZrY1qvxff9/pIlVX+5lrBH53zeJkmzIPHdQy4jQHzEGDE4aQVlN8MB2O7UQUfixAw9R6PXVSReGxySmMrmHiPy5DL0VqH49YqkZowfrrkw1DJUsBDb0qD5DwaKzDWYWRE6Q76g5tICy6QbpkyAemCc0p3bkQLvVN9TfndbJ1pQXSSLFNUo9dZp8RblcF2ZbR+gzVUGwl5M9NjU+6YcoqaPhvFJrGGvzViJWXO0sx7JeUsLruI+5QbxRn8iWjFkEUe9UPK4e281zoyshzlH9JcIaytIDiPMyMQcUifyP5jRsM3OX8lj/97QjkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Vanilla Pytest Output",
            "title": "Vanilla Pytest Output",
            "src": "/static/8d234200a32d468b6c78a223b70f22ff/a6d36/without-clarity.png",
            "srcSet": ["/static/8d234200a32d468b6c78a223b70f22ff/222b7/without-clarity.png 163w", "/static/8d234200a32d468b6c78a223b70f22ff/ff46a/without-clarity.png 325w", "/static/8d234200a32d468b6c78a223b70f22ff/a6d36/without-clarity.png 650w", "/static/8d234200a32d468b6c78a223b70f22ff/8c557/without-clarity.png 700w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In my opinion there are many things wrong with this output, but the most glaring issue is that the it distracts from the actual content of the objects under comparison. The representations of these objects
are interspersed with `}<inlineCode parentName="p">{`+`}</inlineCode>{`, `}<inlineCode parentName="p">{`-`}</inlineCode>{`, `}<inlineCode parentName="p">{`?`}</inlineCode>{`, and `}<inlineCode parentName="p">{`^`}</inlineCode>{` symbols, making it overly difficult to answer the question `}<em parentName="p">{`"what do the objects I'm comparing actually look like, and how do they differ?"`}</em></p>
    <h2>{`Modifying Pytest Output With A Plugin`}</h2>
    <p>{`Pytest has a powerful plugin system, and an active `}<a parentName="p" {...{
        "href": "https://plugincompat.herokuapp.com/"
      }}>{`ecosystem`}</a>{` of useful plugins. The easiest way to get
started with plugin development is to use `}<a parentName="p" {...{
        "href": "https://github.com/hackebrot"
      }}>{`@hackebrot`}</a>{`'s Cookiecutter template for pytest plugins.`}</p>
    <p>{`After we've created the project using the Cookiecutter template, we need to find the `}<em parentName="p">{`hook`}</em>{` that will enable us to customise how assertion errors are reported.`}</p>
    <p>{`Pytest provides a number of hooks, allowing us to add to or customise various aspects of its functionality,
but in this case we need `}<inlineCode parentName="p">{`pytest_assertrepr_compare`}</inlineCode>{`. By creating a function called `}<inlineCode parentName="p">{`pytest_assertrepr_compare`}</inlineCode>{` inside the `}<inlineCode parentName="p">{`pytest_{yourpluginname}.py`}</inlineCode>{` file created by Cookiecutter, we can override the default output pytest prints to the terminal when an assertion fails. `}</p>
    <p>{`The hook has the following signature (type annotations are my own):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def pytest_assertrepr_compare(
    config: _pytest.config.Config,
    op: str,
    left: Any,
    right: Any,
) -> List[str]
`}</code></pre>
    <small>Note: Unfortunately pytest doesn't appear to pass detailed assertion inspection information into this hook, meaning
we can't take full advantage of assertion rewriting unless we inspect the AST ourselves.</small>
    <p>{`The first parameter is a pytest `}<inlineCode parentName="p">{`Config`}</inlineCode>{` object, which we don't need to worry about. The `}<inlineCode parentName="p">{`op`}</inlineCode>{` parameter
refers to the operation used in the `}<inlineCode parentName="p">{`assert`}</inlineCode>{` statement in the test. For example, if your assertion looks
like `}<inlineCode parentName="p">{`assert 1 == 2`}</inlineCode>{`, then `}<inlineCode parentName="p">{`op`}</inlineCode>{` would be `}<inlineCode parentName="p">{`"equal"`}</inlineCode>{`. `}<inlineCode parentName="p">{`left`}</inlineCode>{` and `}<inlineCode parentName="p">{`right`}</inlineCode>{` refer to the values that appear on
the left and right hand side of the `}<inlineCode parentName="p">{`op`}</inlineCode>{`. In the preceding example, `}<inlineCode parentName="p">{`left`}</inlineCode>{` would be `}<inlineCode parentName="p">{`1`}</inlineCode>{` and `}<inlineCode parentName="p">{`right`}</inlineCode>{` would be `}<inlineCode parentName="p">{`2`}</inlineCode>{`. The function returns a list of strings, and each of these strings correspond to a single line of
output that pytest will write to the terminal in the event of a failing assertion.`}</p>
    <h3>{`A Short `}<inlineCode parentName="h3">{`assertrepr_compare`}</inlineCode>{` Plugin Example`}</h3>
    <p>{`Here's a minimal example of using the `}<inlineCode parentName="p">{`assertrepr_compare`}</inlineCode>{` hook, which just prints the left and right operands of the `}<inlineCode parentName="p">{`assert`}</inlineCode>{` statement, as well as the operator used to the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# Basic implementation of the assertrepr_compare hook
def pytest_assertrepr_compare(config, op, left, right):
    return [
        '',  # newline because it looks strange without
        'op: {}'.format(op),
        'left: {}'.format(repr(left)),
        'right: {}'.format(repr(right)),
    ]

# We'll run this test, and check the output
def test_one_equals_two():
    assert 1 == 2
`}</code></pre>
    <p>{`The above implementation of the hook results in the following output after running the test:`}</p>
    <pre><code parentName="pre" {...{}}>{`___________________ test_compare_strings ___________________

    def test_compare_strings():
>       assert 1 == 2
E       assert
E         op: ==
E         left: 1
E         right: 2

tests/test_util.py:64: AssertionError
`}</code></pre>
    <p>{`We now have all the information we need to alter pytest's output into whatever format we wish. Let's look at how pytest currently calculates the diffs it shows the user when an assertion fails.`}</p>
    <h2>{`How Pytest Calculates Diffs`}</h2>
    <p>{`Our plugin will rely heavily on `}<a parentName="p" {...{
        "href": "https://docs.python.org/3.7/library/difflib.html"
      }}><inlineCode parentName="a">{`difflib`}</inlineCode></a>{`, which is included in the Python standard library. `}<inlineCode parentName="p">{`difflib`}</inlineCode>{` provides classes and helper functions for comparing sequences,
and computing deltas (diffs) between these sequences. As it turns out, pytest also uses `}<inlineCode parentName="p">{`difflib`}</inlineCode>{` to display the output you see when your assertion is false. It does so
using the `}<a parentName="p" {...{
        "href": "https://docs.python.org/3.7/library/difflib.html#difflib.ndiff"
      }}><inlineCode parentName="a">{`ndiff`}</inlineCode></a>{` helper function it provides. This function returns a generator which yields strings, each of which corresponds to one line of the delta output.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import difflib
lhs = "hello"
rhs = "world"
delta = difflib.ndiff([lhs], [rhs])
print("\\n".join(delta))
`}</code></pre>
    <p>{` And here's the output, which is identical to the output you'd see in `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` if you were to write `}<inlineCode parentName="p">{`assert "hello" == "world"`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`- hello
+ world
`}</code></pre>
    <p>{`The problem with this approach is that it tightly couples the semantics and the presentation of the delta it generates. It expects that we'll directly output the strings it yields. It'd be nice if we could grab a data structure other than a string which represents the diff itself, and which we can use to generate the colourful output.`}</p>
    <p>{`Luckily, `}<inlineCode parentName="p">{`difflib`}</inlineCode>{` has the answer!`}</p>
    <h2>{`Enter The SequenceMatcher`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`SequenceMatcher`}</inlineCode>{` class is part of `}<inlineCode parentName="p">{`difflib`}</inlineCode>{`, and it provides us with a means of comparing pairs of hashable sequences `}<inlineCode parentName="p">{`A`}</inlineCode>{` and `}<inlineCode parentName="p">{`B`}</inlineCode>{`. We can use it to find the exact index of every element in `}<inlineCode parentName="p">{`A`}</inlineCode>{` that
would have to be replaced, deleted, or inserted, in order to transform `}<inlineCode parentName="p">{`A`}</inlineCode>{` into `}<inlineCode parentName="p">{`B`}</inlineCode>{`.
This is great, because we can now access an abstract
representation of a diff, and we can present it however we desire (colours, `}<em parentName="p">{`everywhere`}</em>{`).`}</p>
    <p>{`Before proceeding, lets look at how to understand and work with the `}<inlineCode parentName="p">{`SequenceMatcher`}</inlineCode>{`. The method we're interested in is called `}<inlineCode parentName="p">{`get_opcodes`}</inlineCode>{`. This method returns a list of
5-tuples which describe how to transform `}<inlineCode parentName="p">{`A`}</inlineCode>{` into `}<inlineCode parentName="p">{`B`}</inlineCode>{`. The Python difflib documentation has a solid `}<a parentName="p" {...{
        "href": "https://docs.python.org/3/library/difflib.html#difflib.SequenceMatcher.get_opcodes"
      }}>{`explanation`}</a>{` of
it, but the code snippet below should give a rough idea of how it works.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import difflib
matcher = difflib.SequenceMatcher(None, "hello", "world")
for tag, i1, i2, j1, j2 in matcher.get_opcodes():
    # tag can be one of 'replace', 'delete', 
    # 'insert', or 'equal', and represents an operation
    # to be performed in order to transform the 
    # left string into the right string.
    # i1:i2 represents a slice of the left string, 
    # j1:j2 a slice of the right string.
    # i1:i2 and j1:j2 are the ranges within the strings that 
    # the operation should be performed on
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`get_opcodes`}</inlineCode>{` method gives us the information we need to determine which colour to write the output to the terminal in.`}</p>
    <h2>{`Formatting Terminal Output`}</h2>
    <p>{`Formatting output to the terminal can be tricky. It works by sending `}<em parentName="p">{`escape codes`}</em>{` to our terminal, which are essentially
"commands", that we represent using a sequence of characters. If the terminal supports the escape code, rather than printing
it, it will perform that command. Terminal escape codes let us do things such as:`}</p>
    <ul>
      <li parentName="ul">{`Change the position of the cursor`}</li>
      <li parentName="ul">{`Change the foreground and background colour of the output text`}</li>
      <li parentName="ul">{`Change the formatting of the output text (`}<em parentName="li">{`italic`}</em>{`, `}<strong parentName="li">{`bold`}</strong>{`, etc.)`}</li>
      <li parentName="ul">{`Make the cursor invisble`}</li>
    </ul>
    <p>{`These escape codes can be difficult to manage, and capabilities can vary depending on the type of terminal you have. The fact that terminal based software such as Vim exists should give an idea of the power that these escape sequences offer. Luckily there are plenty of libraries available which make the process much easier for us. For this plugin, I used the `}<inlineCode parentName="p">{`termcolor`}</inlineCode>{` library. The `}<inlineCode parentName="p">{`colored`}</inlineCode>{` function it provides makes it easy to write colourful and formatted output.`}</p>
    <h3>{`Aside: Partial Function Application In Python`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`colored`}</inlineCode>{` function from `}<inlineCode parentName="p">{`termcolor`}</inlineCode>{` will be used in several places in the plugin. We'll be passing to it a consistent
and verbose set of arguments that we don't want to have to repeat everywhere, so it's a great candidate for `}<em parentName="p">{`partial function
application`}</em>{`! Partial function application lets us "prep" a function by passing in some arguments in advance. Then,
when we want to use the function later, we don't have to pass those arguments in again.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from functools import partial
deleted_text = partial(colored, color=Color.red, attrs=[Attr.bold])
diff_intro_text = partial(colored, color=Color.cyan, attrs=[Attr.bold])
inserted_text = partial(colored, color=Color.green, attrs=[Attr.bold])
`}</code></pre>
    <p>{`Now we can call the functions `}<inlineCode parentName="p">{`deleted_text`}</inlineCode>{`, `}<inlineCode parentName="p">{`diff_intro_text`}</inlineCode>{`, and `}<inlineCode parentName="p">{`inserted_text`}</inlineCode>{` in the same way we
could call `}<inlineCode parentName="p">{`colored`}</inlineCode>{`, but we can omit the `}<inlineCode parentName="p">{`color`}</inlineCode>{` and `}<inlineCode parentName="p">{`attrs`}</inlineCode>{` named arguments, since they've been applied in advance.
Using partial application can make your code more readable, if you give your partially applied functions meaningful names, and use it only where it makes sense.`}</p>
    <h3>{`Terminal Escape Sequences`}</h3>
    <p>{`By default, pytest outputs each line of the assertion report as red text (see the screenshot at the start of this post). We don't want this, so we want to instruct our
terminal to revert back to standard character formatting. Unfortunately I don't think `}<inlineCode parentName="p">{`termcolor`}</inlineCode>{` has a function for this, so we have to send the terminal the escape sequence ourselves. `}</p>
    <p>{`The escape sequence to clear character formatting for VT-100 compliant terminals (the majority of terminal emulators support this) is `}<inlineCode parentName="p">{`\\033[0m`}</inlineCode>{`. `}<em parentName="p">{`ascii-table.com`}</em>{` has a handy `}<a parentName="p" {...{
        "href": "http://ascii-table.com/ansi-escape-sequences-vt-100.php"
      }}>{`reference`}</a>{` listing terminal escape sequences. The `}<inlineCode parentName="p">{`\\033`}</inlineCode>{` part is an octal representation of the decimal value 27. If you look up an ASCII table, you'll find that 27`}<sub>{`dec`}</sub>{` maps to the `}<inlineCode parentName="p">{`ESC`}</inlineCode>{` (escape) character. Then, we have a `}<inlineCode parentName="p">{`[`}</inlineCode>{`, which as far as I know is just a separator. The remainder of the sequence
is an alphanumeric code that maps to a function. In this case, `}<inlineCode parentName="p">{`0m`}</inlineCode>{` maps to the "Clear Character Attributes" command. If we
print out this escape code at the start of every line of output, we'll override pytest when it attempts to print out everything
in bold red characters, and the terminal will output the text in the default format instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def plain_text(string):
    return "\\033[0m" + string
`}</code></pre>
    <h2>{`Putting It All Together`}</h2>
    <p>{`When a test fails, pytest calls the `}<inlineCode parentName="p">{`repr`}</inlineCode>{` function on both sides of the `}<inlineCode parentName="p">{`assert`}</inlineCode>{` statement, and outputs the diff of
these object representations. Rather than relying on `}<inlineCode parentName="p">{`repr`}</inlineCode>{`, we'll use `}<inlineCode parentName="p">{`pprint.pformat`}</inlineCode>{`
which will provide us with a nicely formatted string representation of the object that may span multiple lines for clarity, and make the output more parseable. `}<inlineCode parentName="p">{`pprint.pformat`}</inlineCode>{` also sorts unordered collections such as `}<inlineCode parentName="p">{`dicts`}</inlineCode>{` and `}<inlineCode parentName="p">{`sets`}</inlineCode>{` when
constructing the representation. This is essential, since if the representation of two dicts being compared had different key ordering, we'd get different output every time!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`lhs_repr = pprint.pformat(left, width=width)
rhs_repr = pprint.pformat(right, width=width)
`}</code></pre>
    <p>{`Now that we have our "pretty" representations, we can use the `}<inlineCode parentName="p">{`SequenceMatcher`}</inlineCode>{` from earlier to generate a delta between them,
and our colouring functions to print out text.`}</p>
    <p>{`Here's some code for printing out a split diff (a split diff is where the left and right hand sides of the diffs are printed out independently):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`matcher = difflib.SequenceMatcher(None, lhs_repr, rhs_repr)
for op, i1, i2, j1, j2 in matcher.get_opcodes():

        # Deltas can span multiple lines, but we need to 
        # operate on a line by line basis so we can override
        # pytests attempts to print every individual line red
        lhs_substring_lines = lhs_repr[i1:i2].splitlines()
        rhs_substring_lines = rhs_repr[j1:j2].splitlines()

        # Highlight chars to remove from the left hand side
        for i, lhs_substring in enumerate(lhs_substring_lines):
            if op == 'replace':
                lhs_out += deleted_text(lhs_substring)
            elif op == 'delete':
                lhs_out += deleted_text(lhs_substring)
            elif op == 'insert':
                lhs_out += plain_text(lhs_substring)
            elif op == 'equal':
                lhs_out += plain_text(lhs_substring)

            if i != len(lhs_substring_lines) - 1:
                lhs_out += '\\n'

        # Highlight the stuff to be added on the right hand side
        for j, rhs_substring in enumerate(rhs_substring_lines):
            if op == 'replace':
                rhs_out += inserted_text(rhs_substring)
            elif op == 'insert':
                rhs_out += inserted_text(rhs_substring)
            elif op == 'equal':
                rhs_out += plain_text(rhs_substring)

            if j != len(rhs_substring_lines) - 1:
                rhs_out += '\\n'

    # Return the left and right diffs as lists of strings
    return lhs_out.splitlines(), rhs_out.splitlines()
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`This post was a quick look at some of the code behind `}<inlineCode parentName="p">{`pytest-clarity`}</inlineCode>{`. The plugin is currently available on PyPI, and you can install it using `}<inlineCode parentName="p">{`pip`}</inlineCode>{`:`}</p>
    <p><inlineCode parentName="p">{`pip install pytest-clarity`}</inlineCode></p>
    <p>{`The full project is available on GitHub:`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/darrenburns/pytest-clarity"
      }}>{`https://github.com/darrenburns/pytest-clarity`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      